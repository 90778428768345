import { computed, defineComponent, ref, reactive } from '@vue/composition-api';
import Tip from '@/uikit/Tip.vue';
import { minLength, email, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { server, serverValidationMixin, useValidation, validationRules } from '@/util/validation';
import { TeamMembersDocument, useInviteMembersMutation, useRemoveTeamMemberMutation, useTeamMembersQuery } from '@/generated-types/graphql.types';
import { useResendTimer } from '@/util/hooks';
import { timeZero } from '@/util/filters';
import { ServerErrors } from '@/util/graphql';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
const isTeamMember = (member) => member.__typename === 'TeamMember';
export default defineComponent({
    components: {
        Tip
    },
    mixins: [validationMixin, serverValidationMixin],
    validations: {
        members: {
            _email: server('_email'),
            required,
            minLength: minLength(1),
            $each: {
                email: {
                    required,
                    email
                }
            }
        }
    },
    setup(_, context) {
        const { root } = context;
        const { timer, startTimer } = useResendTimer(root);
        const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
        const { result } = useTeamMembersQuery({
            fetchPolicy: 'cache-and-network'
        });
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const invitationMutationVariables = reactive({
            emails: []
        });
        const invitationMutationOptions = reactive({
            enabled: false,
            refetchQueries: [{ query: TeamMembersDocument }]
        });
        const { mutate: invitationMutation } = useInviteMembersMutation(invitationMutationVariables, invitationMutationOptions);
        const removeTeamMemberMutationVariables = reactive({
            email: ''
        });
        const removeTeamMemberMutationOptions = reactive({
            enabled: false,
            refetchQueries: [{ query: TeamMembersDocument }]
        });
        const { mutate: removeMemberMutation } = useRemoveTeamMemberMutation(removeTeamMemberMutationVariables, removeTeamMemberMutationOptions);
        const confirmation = ref(false);
        const teamMembers = computed(() => result.value?.all_team_members
            ?.filter(_user => isTeamMember(_user) || _user?.account_id !== user.value?.account_id)
            .map(member => ({
            ...member,
            full_name: isTeamMember(member)
                ? null
                : `${member?.first_name} ${member?.last_name}`
        })));
        const deleteTeamMember = (email) => {
            if (!confirmation.value)
                return (confirmation.value = email);
            confirmation.value = false;
            removeTeamMemberMutationVariables.email = email;
            removeTeamMemberMutationOptions.enabled = !!email;
            removeMemberMutation(removeTeamMemberMutationVariables, removeTeamMemberMutationOptions).then(() => {
                addToast({
                    text: root.$i18n.t('team.successfully_removed', { email: email }),
                    type: 'success',
                    dismissAfter: 5000
                });
            });
        };
        const { errors, validate, serverErrors, reset } = useValidation();
        const inviteMembers = async (form) => {
            const emails = form.$model.map(({ email }) => email);
            const isFormValid = validate(form);
            if (!isFormValid || emails.length < 1) {
                errors.fields.email = validationRules.email;
                return;
            }
            invitationMutationVariables.emails = emails;
            invitationMutationOptions.enabled = true;
            await invitationMutation(invitationMutationVariables, invitationMutationOptions)
                .then(data => {
                const invitedUsers = data?.data?.send_teammate_invitations
                    ?.map(user => user?.email)
                    .join(', ');
                addToast({
                    text: root.$i18n.t('team.successfully_invited', {
                        users: invitedUsers
                    }),
                    type: 'success',
                    dismissAfter: 5000
                });
                form.$reset();
                startTimer();
                reset();
                form.$model = [{ email: '' }];
            })
                .catch(({ graphQLErrors }) => {
                if (graphQLErrors) {
                    const graphQLError = ServerErrors.extractGQLError(graphQLErrors);
                    const errorKey = `team.errors.${graphQLError?.email?.error?.key}`;
                    const conflictingEmailsAsText = graphQLError?.email?.meta?.conflicting_emails.join(', ');
                    errors.general = conflictingEmailsAsText
                        ? root.$i18n.t(errorKey, {
                            users: conflictingEmailsAsText.toString()
                        })
                        : root.$i18n.t(errorKey);
                }
            });
        };
        const members = ref([
            {
                email: ''
            }
        ]);
        /** Index is string based on vuelidate */
        const toggleMember = (index) => {
            if (+index === members.value.length - 1) {
                members.value.push({ email: '' });
            }
            else {
                removeMember(index);
            }
        };
        const removeMember = (index) => {
            members.value.splice(+index, 1);
        };
        return {
            confirmation,
            errors,
            members,
            serverErrors,
            teamMembers,
            timer,
            validate,
            deleteTeamMember,
            inviteMembers,
            removeMember,
            timeZero,
            toggleMember
        };
    }
});
